import "styles/pages/register.scss"

import React, { useState, useEffect } from "react"

import Layout from "components/layout"
import Seo from "components/seo"
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs"
import PageHeader from "components/PageHeader/PageHeader"
import PageSubHeader from "components/PageSubHeader/PageSubHeader"
import Button from "components/Button/Button"
import RegisterForm from "components/RegisterForm/RegisterForm"
import { isUserLoggedIn } from "utils/functions"

const Register = () => {
  const title = "Zarejestruj się"
  const [loggedIn, setLoggedIn] = useState(false)

  useEffect(() => {
    const auth = isUserLoggedIn()

    if (!auth) {
      setLoggedIn(true)
    }
  }, [loggedIn])

  return (
    <Layout>
      <Seo title={title} />
      <Breadcrumbs title={title} />

      <section className="register">
        <div className="container-fluid">
          <PageHeader title={title} />

          <div className="row">
            <div className="col-lg-7">
              <PageSubHeader title="Formularz rejestracyjny" />
              <RegisterForm setLoggedIn={setLoggedIn} />
            </div>
            <div className="col-lg-4 offset-lg-1">
              <PageSubHeader title="Dlaczego warto?" />
              <p>
                Jesteśmy na rynku IT nieprzerwanie od 2001 roku. Przez ten czas
                zdobyliśmy mnóstwo cennego doświadczenia, dzięki któremu możemy
                świadczyć obsługę na najwyższym poziomie.
              </p>
              <PageSubHeader
                title="Masz już konto?"
                className="page__sub-header--less-m"
              />
              <Button type="link" to="/logowanie/" className="button--bordered">
                Zaloguj się
              </Button>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Register
